import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import LaneService from "../services/LaneService";
import Select from "react-select";
import 'react-tooltip/dist/react-tooltip.css'
import CompanyLogo from "../assets/images/farelanes-logo-gold.png";

import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

import AsyncSelect from "react-select/async";
import RatesMap from "./RatesMap";
import {Rmaps} from "./RMaps";
import UserService from "../services/UserService";
import {initAmplitude, sendAmplitudeData, setAmplitudeUserId} from "../common/amplitude";
import 'react-data-grid/lib/styles.css';
import {ProfileWithoutPageLayoutComponent} from "../pages/ProfileWithoutPageLayout";
import {PageLoader} from "./page-loader";
import {PriceBlockComponent} from "./PriceBlock";
import DataGrid from "react-data-grid";
import {Tab, Tabs} from "react-tabs";
import Modal from "react-modal";

export const CitySearch = () => {

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            // padding: '5px 10px',
            border: '1px solid black',
            // boxShadow: '0 2px 4px rgba(0,0,0,.2)',
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted green',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'gray' : 'white',
        }),
    };

    const columnsDataGrid = [

        {key: 'equipment', name: 'Equipment'},
        {key: 'miles', name: 'Miles'},
        {key: 'pick_city', name: 'Pick City'},
        {key: 'pick_state', name: 'Pick State'},
        {key: 'drop_city', name: 'Drop City'},
        {key: 'drop_state', name: 'Drop State'},
        {key: 'pick_zip', name: 'Pick Zip'},
        {key: 'drop_zip', name: 'Drop Zip'},
        {key: 'pick_date', name: 'Pick Date'},
        {key: 'drop_date', name: 'Drop Date'},
        {key: 'line_haul_amount', name: 'Line Haul Amt ($)'},
        {key: 'lane_rate', name: 'Lane Rate ($)'},
        {key: 'number_of_stops', name: 'Number of Stops'},
        {key: 'fuel_surcharge', name: 'Fuel Surcharge'},
        {key: 'intercharge', name: 'Intercharge'},
        // {key: 'lead_time', name: 'Lead Time'},
        {key: 'lumper', name: 'Lumper'},

        {key: 'other_fee', name: 'Other Fee'},
        {key: 'pallet_charge', name: 'Pallet Charge'},
        {key: 'truck_order', name: 'Truck Order'},
        {key: 'detention', name: 'Detention'},
    ];

    let isMobile = null;

    if (typeof window !== "undefined") {
        isMobile = window.innerWidth < 450;
    }

    const [state, setState] = useState({
        showResult: false,
        apiMessage: undefined,
        error: null,
        subscription: false,
        subscriptionType: 'Default'
    });

    const {user, getAccessTokenSilently} = useAuth0();

    const callApi = async () => {
        const token = await getAccessTokenSilently();

        UserService.userSubscriptionStatusPair(token, user).then(
            response => {
                setState({
                    subscription: response.data.status,
                    subscriptionType: response.data.subscriptionType
                })

                LaneService.getUserRateSearchOptions(token).then(
                    response => {

                        console.log('PANA');
                        console.log(response);
                        let l = [];
                        for (let item in response) {
                            console.log(response[item])
                            l.push({
                                label: response[item].equipment + ' -> ' + response[item].pick_city + ', ' + response[item].pick_state + ' ' +  + response[item].pick_radius_miles + ' mi.' +  ' -> ' + response[item].drop_city + ', ' + response[item].drop_state + ' ' +   + response[item].radius_miles + ' mi.',
                                value:  response[item]})
                        }
                        setSearchOptions(l)
                    },
                    error => {
                        console.log(error);
                    }
                );
            },
            error => {

            }
        )
    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log('Call() finished'));
    }, []);


    const [message, setMessage] = React.useState("");
    const [apiFinished, setApiFinished] = React.useState(true);

    const [tabIndex, setTabIndex] = useState(1);

    const checkCondition = true;


    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal30Days() {
        setTabIndex(0);
        setIsOpen(true);
    }



    let [searchOptions, setSearchOptions] = useState([{value: 'Apples', label: 'Apples'},{value: 'Mangoes', label: 'Mangoes'}
    ]);

    const [open, setOpen] = useState(false);

    const onOpenModals = () => setOpen(true);
    const onCloseModals = () => setOpen(false);

    function openModal15Days() {
        setTabIndex(1);
        setIsOpen(true);
    }

    function openModal7Days() {
        setTabIndex(2);
        setIsOpen(true);
    }

    function openModal3Days() {
        setTabIndex(3);
        setIsOpen(true);
    }

    function openModal1Days() {
        setTabIndex(4);
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );

    const labels = ['15 days', '7 days', 'Three days', 'Yesterday'];

    const [data, setData] = useState(undefined);

    const equipmentOptions = [
        {value: 'Auto Carrier', label: 'Auto Carrier'},
        {value: 'Container', label: 'Container'},
        {value: 'Conestoga', label: 'Conestoga'},
        {value: 'Dry Van', label: 'Dry Van'},
        {value: 'Flatbed', label: 'Flatbed'},
        {value: 'Hopper Bottom', label: 'Hopper Bottom'},
        {value: 'Lowboy', label: 'Lowboy'},
        {value: 'Maxi', label: 'Maxi'},
        {value: 'Stretch Trailer', label: 'Stretch Trailer'},
        {value: 'Removable Gooseneck', label: 'Removable Gooseneck'},
        {value: 'Power Only', label: 'Power Only'},
        {value: 'Pneumatic', label: 'Pneumatic'},
        {value: 'Double Drop', label: 'Double Drop'},
        {value: 'Reefer', label: 'Reefer'},
        {value: 'Dump Trailer', label: 'Dump Trailer'},
        {value: 'Straight Box', label: 'Straight Box'},
        {value: 'Box Truck', label: 'Box Truck'},
        {value: 'Drop Deck', label: 'Drop Deck'},
        {value: 'Step Deck', label: 'Step Deck'},
        {value: 'Sprinter', label: 'Sprinter'},
        {value: 'Conveyor', label: 'Conveyor'},
        {value: 'Tanker', label: 'Tanker'},
        {value: 'Truck and Trailer', label: 'Truck & Trailer'},
    ]

    const [submitted, setSubmitted] = useState(false);
    const [laneStatistics, setLaneStatistics] = useState('default');
    const [inputPickCityValue, setPickCityValue] = useState('');
    const [inputDropCityValue, setDropCityValue] = useState('');
    const [selectedPickCityValue, setSelectedPickCityValue] = useState(null);
    const [selectedDropCityValue, setSelectedDropCityValue] = useState(null);
    const [selectedRateSearchHistoryValue, setSelectedRateSearchHistoryValue] = useState(null);
    const [searchHistoryValue, setSearchHistoryValue] = useState({value: 'Dry Van', label: 'Recent Search History'});
    const [equipmentValue, setEquipmentValue] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [rateSearchHistoryValue, setRateSearchHistoryValue] = useState({
        pick_state: '', radius_miles: 'Dry Van',
        pick_city: 'Select user search history here'
    });
    const [radiusValue, setRadiusValue] = useState({value: 200, label: '200 miles'});
    const [pickCityRadiusValue, setPickCityRadiusValue] = useState({value: 200, label: '200 miles'});


    const handleEquipmentChange = (event) => {
        setEquipmentValue(event);
    };

    const handleSearchValueChange = (event) => {
        setSearchHistoryValue(event);
        setSelectedPickCityValue({city: event.value.pick_city, stateName: event.value.pick_state,lng: event.value.pick_lng,lat: event.value.pick_lat});
        setSelectedDropCityValue({city: event.value.drop_city, stateName: event.value.drop_state,lng: event.value.drop_lng,lat: event.value.drop_lat});
        setEquipmentValue({value: event.value.equipment, label: event.value.equipment});
        setRadiusValue({value: event.value.radius_miles, label: event.value.radius_miles + ' miles'});
        setPickCityRadiusValue({value: event.value.pick_radius_miles, label: event.value.pick_radius_miles + ' miles'});
        // handleFormSubmit().then(r => console.log('c o m p l e t e d'));
    };

    const handleRadiusChange = (event) => {
        setRadiusValue(event);
    };

    const handlePickCityRadiusChange = (event) => {
        setPickCityRadiusValue(event);
    };

    const radiusOptions = [
        {value: 50, label: '50 miles'},
        {value: 100, label: '100 miles'},
        {value: 150, label: '150 miles'},
        {value: 200, label: '200 miles'},
        {value: 250, label: '250 miles'}
    ]

    const pickCityRadiusOptions = [
        {value: 50, label: '50 miles'},
        {value: 100, label: '100 miles'},
        {value: 150, label: '150 miles'},
        {value: 200, label: '200 miles'},
        {value: 250, label: '250 miles'}
    ]

    // handle input change event
    const handlePickCityInputChange = value => {
        setPickCityValue(value);
    };

    // handle selection
    const handlePickCityChange = value => {
        setSelectedPickCityValue(value);
    }

    const handleDropCityInputChange = value => {
        setDropCityValue(value);
    };

    // handle selection
    const handleDropCityChange = value => {
        setSelectedDropCityValue(value);
    }

    const financial = (x) => Number.parseFloat(x).toFixed(0);

    const handleFormSubmit = async value => {
        const token = await getAccessTokenSilently();
        if (null == selectedPickCityValue || null == selectedDropCityValue) {
            setMessage('Pick or drop city cannot be empty.')
            return;
        }
        setMessage('');
        setApiFinished(false);

        initAmplitude();

        setAmplitudeUserId(user.email);
        console.log('Setting Ampl user' + user.email);

        // sendAmplitudeData('Clicked Homepage Button', {'finished_flow': false, 'clicks': 15});

        sendAmplitudeData("Rates Search", {
            'pick_city': selectedPickCityValue.city,
            'pick_state': selectedPickCityValue.stateName,
            'end_city': selectedDropCityValue.city,
            'end_state': selectedDropCityValue.stateName,
            'equipment': equipmentValue.value,
            'pick_city_radius_miles': pickCityRadiusValue.value,
            'drop_city_radius_miles': radiusValue.value,
        });

        LaneService.getLaneStatistics(selectedPickCityValue.city, selectedPickCityValue.stateName, selectedDropCityValue.city, selectedDropCityValue.stateName, equipmentValue.value, radiusValue.value, pickCityRadiusValue.value, token).then(
            response => {
                setLaneStatistics(response.data);

                let mapsData = [
                    {
                        name: '30 DAYS',
                        uv: 1400,
                        pv: response.data.thirtyDaysAverage.averageLoadRate,
                        amt: 2400,
                    },
                    {
                        name: '15 DAYS',
                        uv: 1400,
                        pv: response.data.fifteenDaysAverage.averageLoadRate,
                        amt: 2400,
                    },
                    {
                        name: '7 DAYS',
                        uv: 1795,
                        pv: response.data.sevenDaysAverage.averageLoadRate,
                        amt: 2210,
                    },
                    {
                        name: '3 DAYS',
                        uv: 1500,
                        pv: response.data.threeDaysAverage.averageLoadRate,
                        amt: 2290,
                    },
                    {
                        name: 'YESTERDAY',
                        uv: 1965,
                        pv: response.data.oneDaysAverage.averageLoadRate,
                        amt: 2000,
                    }
                ];

                LaneService.getUserRateSearchOptions(token).then(
                    response => {

                        let l = [];
                        for (let item in response) {
                            l.push({
                                label: response[item].equipment + ' -> ' + response[item].pick_city + ', ' + response[item].pick_state + ' ' +  + response[item].pick_radius_miles + ' mi.' +  ' -> ' + response[item].drop_city + ', ' + response[item].drop_state + ' ' +   + response[item].radius_miles + ' mi.',
                                value: response[item]})
                        }
                        setSearchOptions(l);
                    },
                    error => {
                        console.log(error);
                    }
                );


                setData({
                    mapsData
                })
                setApiFinished(true);
            },
            error => {
                if (error.response && error.response.status === 401) {
                    // AuthService.logout();
                    // window.location.replace('/login');
                    // EventBus.dispatch("logout");
                }
            }
        );
        setSubmitted(true);
    }

    // load city/zips using API call
    const loadOptions = async (inputValue) => {
        const token = await getAccessTokenSilently();
        return LaneService.getCityOptions(inputValue, token).then(
            response => {
                return response;
            },
            error => {
                console.log(error);
            }
        );
    };

    // load city/zips using API call
    const loadSearchHistoryOptions = async (inputValue) => {
        const token = await getAccessTokenSilently();
        return LaneService.getUserRateSearchOptions(token).then(
            response => {
                return response;
            },
            error => {
                console.log(error);
            }
        );
    };

    return (
        <>
            <div className="AppFonts container-fluid">
                {state.subscription &&
                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor="exampleFormControlFile1"><b>Search History</b></label>
                        <Select
                            styles={customStyles}
                            label="What equipment"
                            options={searchOptions}
                            value={searchHistoryValue}
                            onChange={handleSearchValueChange}
                        />
                    </div>
                </div>
                }

                <hr/>
                {!state.subscription && (<ProfileWithoutPageLayoutComponent/>)}
                {state.subscription &&
                    <form>
                        <div className="">
                            <div className="row rates-search-params-border">
                                {/*Equipment Select*/}
                                <div className="col-sm-3 col-lg-2">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue}
                                        onChange={handleEquipmentChange}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm-3">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange}
                                        onChange={handlePickCityChange}
                                    />
                                </div>


                                <div className="col-sm-2 col-lg-2">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={pickCityRadiusOptions}
                                        value={pickCityRadiusValue}
                                        onChange={handlePickCityRadiusChange}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm-3">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange}
                                        onChange={handleDropCityChange}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm-2 col-lg-2">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue}
                                        onChange={handleRadiusChange}
                                    />
                                </div>


                            </div>

                            <div className="row bb">
                                <div className="col-sm text-center ">
                                    <label htmlFor="exampleFormControlFile1">&nbsp;</label>
                                    <button onClick={handleFormSubmit} type="button"
                                            className="button__get-rates more_style">
                                        <span className="font-weight-bold">Get Rates</span>
                                    </button>
                                </div>
                            </div>
                            <small>&nbsp;{message}</small>
                        </div>
                    </form>
                }


                {/*Search Box Over here*/}

                {!apiFinished && (<div className="text-center p-3">
                    <PageLoader></PageLoader>
                </div>)}
                {submitted && apiFinished && (<div>

                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                    >
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
                        <img className="popup-logo" src={CompanyLogo}/>
                        <button className="btn-success close-button"
                                onClick={closeModal}>Close
                        </button>
                        <hr/>


                        {/*<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>*/}
                        <Tabs defaultActiveKey={tabIndex} onSelect={(index) => setTabIndex(index)}>

                            <Tab eventKey="0" title="30 Days">

                                <div
                                    className="alert-success align-content-center align-farelanes-content-center">
                                                                <span>30 Days reports, total number of reports
                                                                    &nbsp; <span className=""><bold>{laneStatistics.thirtyDaysAverage && laneStatistics.thirtyDaysAverage.documentCount}</bold></span></span>
                                </div>

                                {
                                    laneStatistics.thirtyDaysAverage.collection && laneStatistics.thirtyDaysAverage.collection.forEach((obj) => {
                                        delete obj.dataSource;
                                        delete obj.inv_entry_date;
                                        delete obj.id
                                    })
                                }
                                <DataGrid
                                    className="rdg-light"
                                    style={{height: '800px'}}
                                    columns={columnsDataGrid}
                                    rows={laneStatistics.thirtyDaysAverage && laneStatistics.thirtyDaysAverage.collection}/>

                            </Tab>
                            <Tab eventKey="1" title="15 Days">

                                <div
                                    className="alert-success align-content-center align-farelanes-content-center">
                                                                <span>15 Days reports, total number of reports
                                                                    &nbsp; <span className=""><bold>{laneStatistics.fifteenDaysAverage && laneStatistics.fifteenDaysAverage.documentCount}</bold></span></span>
                                </div>


                                {
                                    laneStatistics.fifteenDaysAverage.collection && laneStatistics.fifteenDaysAverage.collection.forEach((obj) => {
                                        delete obj.dataSource;
                                        delete obj.inv_entry_date;
                                        delete obj.id
                                    })
                                }


                                <DataGrid
                                    className="rdg-light"
                                    style={{height: '800px'}}
                                    columns={columnsDataGrid}
                                    rows={laneStatistics.fifteenDaysAverage && laneStatistics.fifteenDaysAverage.collection}/>
                            </Tab>
                            <Tab eventKey="2" title="7 Days">


                                {
                                    laneStatistics.sevenDaysAverage && laneStatistics.sevenDaysAverage.collection.forEach((obj) => {
                                        delete obj.dataSource;
                                        delete obj.inv_entry_date;
                                        delete obj.id
                                    })
                                }

                                <div
                                    className="alert-success align-content-center align-farelanes-content-center">
                                                                <span>7 Days reports, total number of reports
                                                                    &nbsp; <span className=""><bold>{laneStatistics.sevenDaysAverage && laneStatistics.sevenDaysAverage.documentCount}</bold></span></span>
                                </div>

                                <DataGrid
                                    className="rdg-light"
                                    style={{height: '800px'}}
                                    columns={columnsDataGrid}
                                    rows={laneStatistics.sevenDaysAverage && laneStatistics.sevenDaysAverage.collection}/>
                            </Tab>
                            <Tab eventKey="3" title="3 Days">
                                <div
                                    className="alert-success align-content-center align-farelanes-content-center">
                                                                <span>3 Days reports, total number of reports
                                                                    &nbsp; <span className=""><bold>{laneStatistics.threeDaysAverage && laneStatistics.threeDaysAverage.documentCount}</bold></span></span>
                                </div>

                                {
                                    laneStatistics.threeDaysAverage.collection && laneStatistics.threeDaysAverage.collection.forEach((obj) => {
                                        delete obj.dataSource;
                                        delete obj.inv_entry_date;
                                        delete obj.id
                                    })
                                }

                                <DataGrid
                                    className="rdg-light"
                                    style={{height: '800px'}}
                                    columns={columnsDataGrid}
                                    rows={laneStatistics.threeDaysAverage && laneStatistics.threeDaysAverage.collection}/>
                            </Tab>
                            <Tab eventKey="4" title="Yesterday">
                                <div
                                    className="alert-success align-content-center align-farelanes-content-center">
                                                                <span>Yesterdays reports, total number of reports
                                                                    &nbsp; <span className=""><bold>{laneStatistics.oneDaysAverage && laneStatistics.oneDaysAverage.documentCount}</bold></span></span>
                                </div>

                                {
                                    laneStatistics.oneDaysAverage.collection && laneStatistics.oneDaysAverage.collection.forEach((obj) => {
                                        delete obj.dataSource;
                                        delete obj.inv_entry_date;
                                        delete obj.id
                                    })
                                }

                                <DataGrid
                                    className="rdg-light"
                                    style={{height: '800px'}}
                                    columns={columnsDataGrid}
                                    rows={laneStatistics.oneDaysAverage && laneStatistics.oneDaysAverage.collection}/>
                            </Tab>

                        </Tabs>
                    </Modal>

                    <div className="row rates-search-price-chart">
                        <Rmaps ticker={{dataMaps: data}}></Rmaps>
                    </div>
                    <div className="row rates-search-price-border rates_border">
                        <div className="col"><PriceBlockComponent object={{
                            days: '30 days',
                            laneStatistics: laneStatistics.thirtyDaysAverage,
                            subscriptionType: state.subscriptionType,
                            onClick: openModal30Days
                        }}>
                        </PriceBlockComponent>
                        </div>

                        <div className="col"><PriceBlockComponent object={{
                            days: '15 days',
                            laneStatistics: laneStatistics.fifteenDaysAverage,
                            subscriptionType: state.subscriptionType,
                            onClick: openModal15Days
                        }}></PriceBlockComponent></div>
                        <div className="col"><PriceBlockComponent object={{
                            days: '7 days',
                            laneStatistics: laneStatistics.sevenDaysAverage,
                            subscriptionType: state.subscriptionType,
                            onClick: openModal7Days
                        }}></PriceBlockComponent></div>
                        <div className="col"><PriceBlockComponent object={{
                            days: '3 days',
                            laneStatistics: laneStatistics.threeDaysAverage,
                            subscriptionType: state.subscriptionType,
                            onClick: openModal3Days
                        }}></PriceBlockComponent></div>
                        <div className="col"><PriceBlockComponent object={{
                            days: '1 days',
                            laneStatistics: laneStatistics.oneDaysAverage,
                            subscriptionType: state.subscriptionType,
                            onClick: openModal1Days
                        }}></PriceBlockComponent></div>
                    </div>
                    <div className="row rates_border">
                        <div className="col"><RatesMap latlng={{
                            isMobile: isMobile,
                            pickCityLat: selectedPickCityValue.lat,
                            pickCityLng: selectedPickCityValue.lng,
                            dropCityLat: selectedDropCityValue.lat,
                            dropCityLng: selectedDropCityValue.lng,
                        }}></RatesMap>
                        </div>
                    </div>
                </div>)
                }

                <div className="row">
                    <br/>
                </div>
            </div>
        </>
    );
};

export default withAuthenticationRequired(CitySearch, {
    onRedirecting: () => <PageLoader/>,
});
