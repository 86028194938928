import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import {NavBarTab} from "./nav-bar-tab";
import {DropdownItem} from "reactstrap";
import {NavLink as RouterNavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserService from "../../../services/UserService";
import LaneService from "../../../services/LaneService";

export const NavBarTabs = () => {
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: undefined,
        error: null,
        subscription: false,
        subscriptionType: 'Default'
    });

    const callApi = async () => {
        const token = await getAccessTokenSilently();

        UserService.userSubscriptionStatusPair(token, user).then(
            response => {
                setState({
                    subscription: response.data.status,
                    subscriptionType: response.data.subscriptionType
                })
            },
            error => {

            }
        )


    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log('Call() finished'));
    }, []);
    return (
        <div className="nav-bar__tabs">
            {isAuthenticated && (
                <>
                    <NavBarTab path="/dashboard" label="Dashboard"/>
                    <NavBarTab path="/rates" label="Rates"/>
                    <NavBarTab path="/top-lanes" label="Top Lanes"/>
                    <NavBarTab path="/settings" label="Settings"/>

                    {isAuthenticated && (user.email == 'brad.kozak@farelanes.com' || user.email == 'casey.larkin@farelanes.com' || user.email == 'andrew.cohen@farelanes.com' || user.email == 'neal.huffman@farelanes.com' || user.email == 'kapil.reddy@farelanes.com') && (
                        <NavBarTab path="/insights" label="Insights"/>
                    )}

                    {state.subscriptionType == 'Gold' && (<NavBarTab path="/rfq" label="RFQ"/>)}

                    {isAuthenticated && (user.email == 'brad.kozak@farelanes.com' || user.email == 'casey.larkin@farelanes.com' || user.email == 'andrew.cohen@farelanes.com' || user.email == 'neal.huffman@farelanes.com' || user.email == 'kapil.reddy@farelanes.com') && (
                        <NavBarTab path="/admin" label="Administration"/>
                    )}
                </>
            )}
        </div>
    );
};
