import React, {Fragment} from "react";
import Ticker from "../components/Ticker";
import {LaneData} from "../components/LaneData";
import {PageLayout} from "../components/page-layout";

const Dashboard = () => (
    <PageLayout>
        <Fragment>
            <Ticker ticker={{id: 1}}></Ticker>
            <Ticker ticker={{id: 2}}></Ticker>
            <LaneData></LaneData>

        </Fragment>
    </PageLayout>
);

export default Dashboard;
