import React from "react";

import LoadingLogo from "../assets/images/loader.svg";

export const PageLoader = () => {

  return (
    <div className="loader">
      <img src={LoadingLogo} alt="Loading..." />
    </div>
  );
};
