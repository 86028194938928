import React from "react";
import { Auth0Feature } from "./auth0-feature";

import FarelanesBackground from "../assets/images/farelanes-splash.jpg";
import SILVERTOP from "../assets/images/pricing/farelanes-silver-sales-top.png";
import GOLDTOP from "../assets/images/pricing/farelanes-gold-sales-top.png";
import PLATINUMTOP from "../assets/images/pricing/farelanes-platinum-sales-top.png";
// import PLATINUMTOP from "../assets/images/pricing/pricing-details.png";

import {Button} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {SignupButton} from "./buttons/signup-button";
import {LoginButton} from "./buttons/login-button";
import {SignupButtonOne} from "./buttons/signup-button-one";
import {NavBarBrand} from "./navigation/desktop/nav-bar-brand";
import {NavBarTabs} from "./navigation/desktop/nav-bar-tabs";
import {NavBarButtons} from "./navigation/desktop/nav-bar-buttons";

export const Auth0FeaturesMobile = () => {

  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();



  return (
      <div className="auth0-features">
        <div className="form-group ">
          <div>
            <div>
              <section id="pricing" className="pricing-content section-padding">
                <div className="container">
                  <div className="section-title text-center">
                  </div>
                  <div className="row text-center">
                    <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp "
                         data-wow-duration="1s"
                         data-wow-delay="0.1s" data-wow-offset="0">
                      <div className="mobile-home">
                        <div className="single-pricing">
                          <div className="price-head">
                            {/*<h2>Silver</h2>*/}
                            <img className="silver-top" src={SILVERTOP}/>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp  "
                         data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                      <div className="mobile-home">
                        <div className="single-pricing">
                          <div className="price-head">
                            <div className="price-head">
                              {/*<h2>Silver</h2>*/}
                              <img className="silver-top" src={GOLDTOP}/>

                            </div>
                          </div>

                          {/*<a href="#" className="price_btn disabled-link">Order Now</a>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp  "
                         data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                      <div className="mobile-home">
                        <div className="single-pricing ">

                          {/*<div className="overlay">*/}
                          {/*    */}
                          {/*</div>*/}
                          <div className="price-head ">
                            <div className="price-head">
                              {/*<h2>Silver</h2>*/}
                              <img className="silver-top" src={PLATINUMTOP}/>
                            </div>

                            <hr/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

          </div>
        </div>
      </div>
  );
};
