import React, {Fragment} from "react";
import Ticker from "../components/Ticker";
import {LaneData} from "../components/LaneData";
import {PageLayout} from "../components/page-layout";
import DragDrop from "../components/drag-drop";

const FileUploadProcessor = () => (
    <PageLayout>
            <DragDrop></DragDrop>
    </PageLayout>
);

export default FileUploadProcessor;
