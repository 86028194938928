import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";

export const PageFooter = () => {


  return (
      <div className="bg-light p-3 text-center farelanes-footer">
        <div id="sticky-footer" className="fixed-bottom py-2 bg-light text-white-50"/>
        <p>Copyright © 2024 ·  Farelanes LLC, All Rights Reserved.</p>
        {/*<small className="the-footer">©2024 <a href="https://farelanes.com">Farelanes</a>. All Rights Reserved.  <a*/}
        {/*    href="https://farelanes.com/privacy-policy/">Privacy</a> </small>*/}

      </div>
  );
};
