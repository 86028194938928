import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import UserService from "../services/UserService";
import Progress from "./Progress";

import { Parser } from 'html-to-react';
import {PageLoader} from "./page-loader";

export const TickerComponent = (props) => {

    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.getTicker(token, user, props.ticker.id).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                    });
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    useEffect(() => {

        // declare the data fetching function
        callApi();

    }, []);


    return (
        <div className="container-fluid">
            {/*{!state.showResult && (<div><Progress></Progress></div>)}*/}
            {!state.showResult && (<div className="text-center p-3">
                <PageLoader></PageLoader>
            </div>)}
            {state.showResult && (<div className=" ">
                    <fieldset className="the-fieldset-ticker-one">
                        <legend className="the-legend">{state.apiMessage.tickerName}</legend>
                        <div className="ticker">
                            <div className="">
                                <marquee className="">
                                    {/*{state.apiMessage.feed}*/}
                                    {Parser().parse(state.apiMessage.feed)}
                                </marquee>


                            </div>
                        </div>
                    </fieldset>
                </div>
            )}
        </div>
    );
};

export default withAuthenticationRequired(TickerComponent, {
    onRedirecting: () => <Loading/>,
});
