import React, {useEffect, useState} from "react";
import {Controller, useForm} from 'react-hook-form';
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";
import {toast, ToastContainer} from "react-toastify";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {PageLayout} from "./page-layout";
import {useNavigate} from "react-router-dom";

const isNumber = (number) => !isNaN(number) || "Must be a number";


export const ProfileDetails = (props) => {

    let navigate = useNavigate();
    const registerOptions = {
        email: {required: "Email is required"},
        city: {
            required: "City is required",
            maxLength: {
                value: 108,
                message: "City cannot be more than 108 characters"
            }
        },
        zip: {
            required: "Zip is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        state: {
            required: "State is required",
            maxLength: {
                value: 50,
                message: "State cannot be more than 50 characters"
            }
        },
        country: {
            required: "Country is required",
            maxLength: {
                value: 50,
                message: "State cannot be more than 50 characters"
            }
        },
        addressLineOne: {
            required: "Address is required",
            maxLength: {
                value: 125,
                message: "Address cannot be more than 125 characters"
            }
        },
        addressLineTwo: {
            maxLength: {
                value: 75,
                message: "Address cannot be more than 75 characters"
            }
        },
        firstName: {
            required: "FirstName is required",
            maxLength: {
                value: 75,
                message: "FirstName cannot be more than 75 characters"
            }
        },
        lastName: {
            required: "Last Name is required",
            maxLength: {
                value: 75,
                message: "Lastname cannot be more than 75 characters"
            }
        }
    };

    const [howDoYouKnowUs, setHowDoYouKnowUs] = useState("1");

    const onOptionChange = e => {
        setHowDoYouKnowUs(e.target.value)
    }

    const [value, setValue] = useState(null);
    let reference = React.createRef();

    function friend(value) {
        setValue(value);
        geocodeByPlaceId(value.value.place_id)
            .then(results => {


                //Preparation

                let addressLineOne = '';
                let city = '';
                let state = '';
                let country = '';
                let zip = '';
                let mobileNumber = farelanesUser.mobileNumber;
                let formatted_address = '';

                for (let i = 0; i < results[0].address_components.length; i++) {
                    let types = results[0].address_components[i].types;

                    if (types.includes('street_number')) {
                        addressLineOne = results[0].address_components[i].long_name;
                    }

                    if (types.includes('route')) {
                        addressLineOne = addressLineOne + ' ' + results[0].address_components[i].long_name
                    }

                    if (types.includes('locality')) {
                        city = results[0].address_components[i].long_name
                    }

                    if (types.includes('administrative_area_level_1')) {
                        state = results[0].address_components[i].short_name
                    }

                    if (types.includes('country')) {
                        country = results[0].address_components[i].short_name
                    }
                    if (types.includes('postal_code')) {
                        zip = results[0].address_components[i].short_name;
                    }

                    if (types.includes('postal_code_suffix')) {
                        zip = zip + '-' + results[0].address_components[i].short_name;
                    }
                }

                setFarelanesUser({
                    ...state,
                    addressLineOne: addressLineOne,
                    city: city,
                    state: state,
                    country: country,
                    zipCode: zip,
                    mobileNumber: mobileNumber
                });

            })
            .catch(error => console.error(error));
    }

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
    });

    const [farelanesUser, setFarelanesUser] = useState(null);

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                        farelanesUser: {firstName: 'Frank', lastName: 'Murphy'}
                    });
                    setFarelanesUser({
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        mobileNumber: response.data.mobileNumber || '1',
                        addressLineOne: response.data.addressLineOne,
                        addressLineTwo: response.data.addressLineTwo,
                        state: response.data.state,
                        city: response.data.city,
                        country: response.data.country,
                        zipCode: response.data.zipCode,
                    });

                    setHowDoYouKnowUs(response.data.howDoYouKnowUs)
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    async function onSubmit(data) {
        // display form data on submit
        const token = await getAccessTokenSilently();
        setState({
            ...state,
            showResult: false
        });

        UserService.putUser({
            firstName: data.firstName,
            lastName: data.lastName,
            mobileNumber: data.mobileNumber,
            addressLineOne: data.addressLineOne,
            addressLineTwo: data.addressLineTwo,
            state: data.state,
            city: data.city,
            country: data.country,
            zipCode: data.zipCode,
            sub: user.sub,
            howDoYouKnowUs: howDoYouKnowUs
        }, token).then(response => {
            setState({
                ...state,
                showResult: true
            });
            toast("User updated successfully.");
            navigate("/subscription-refresh", {state: {refresh: true}});
        })
        return true;
    }


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);

    return <section className="">
        <ToastContainer/>
        {farelanesUser &&
            <div className="AppFonts_Tickers">
                <div className="">
                    <div className="row ">
                        <div className="col-12 col-lg-9 col-xl-7">
                            <div className="card">
                                <div className="card-body p-4 p-md-5">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {/*First Name and Last Name*/}
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="firstName">First Name</label>
                                                    <input className="form-control username-field" id="firstName"
                                                           {...register("firstName", registerOptions.firstName)}
                                                    />

                                                    <small className="text-danger">
                                                        {errors?.firstName && errors.firstName.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="lastName">Last Name</label>
                                                    <input
                                                        className="form-control username-field" {...register("lastName", registerOptions.lastName)} />
                                                </div>
                                            </div>
                                            <small className="text-danger">
                                                {errors?.lastName && errors.lastName.message}
                                            </small>
                                        </div>
                                        {/*-end-First Name and Last Name*/}


                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address1">Search your
                                                        address</label>

                                                    {/*<input className="form-control"  type="text" ref={ref}/>*/}

                                                    <div>
                                                        <div>
                                                            <GooglePlacesAutocomplete
                                                                apiKey="AIzaSyDaLZOaYvwAJGsem6pjU8Vg80he7vi2uf0"
                                                                selectProps={{
                                                                    value,
                                                                    onChange: friend,
                                                                }}
                                                                autocompletionRequest={{
                                                                    bounds: [
                                                                        {lat: 50, lng: 50},
                                                                        {lat: 100, lng: 100}
                                                                    ],
                                                                    types: ["address"],
                                                                    componentRestrictions: {
                                                                        country: ['us'],
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Address Line 1 and Address Line 2*/}
                                        <div className="row">
                                            <div className="col-md-12 mb-9">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address1">Address Line
                                                        1</label>
                                                    <input className="form-control username-field"
                                                           placeholder={"Line One"} {...register("addressLineOne", registerOptions.addressLineOne)} />
                                                </div>
                                            </div>

                                        </div>
                                        <small className="text-danger">
                                            {errors?.addressLineOne && errors.addressLineOne.message}
                                        </small>
                                        <div className="row">

                                            <div className="col-md-12 mb-9">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address2">Apartment, unit,
                                                        suite, or floor #
                                                    </label>
                                                    <input className="form-control username-field"
                                                           placeholder={"Apartment, unit, suite, or floor #"} {...register("addressLineTwo", registerOptions.addressLineTwo)} />
                                                </div>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors?.addressLineTwo && errors.addressLineTwo.message}
                                        </small>
                                        {/*-end-Address Line 1 and Address Line 2e*/}


                                        {/*City Line 1 and Zip*/}
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address1">City</label>
                                                    <input className="form-control username-field"
                                                           placeholder={"City"} {...register("city", registerOptions.city)} />


                                                </div>
                                                <small className="text-danger">
                                                    {errors?.city && errors.city.message}
                                                </small>

                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address2">Zip</label>
                                                    <input
                                                        className="form-control username-field" {...register("zipCode", registerOptions.zip)} />
                                                </div>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors?.zipCode && errors.zipCode.message}
                                        </small>

                                        {/*State 1 and Country*/}
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address1">State</label>
                                                    <select
                                                        className="form-control username-field" {...register("state", registerOptions.state)}>
                                                        <option value="">Select</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <small className="text-danger">
                                                {errors?.state && errors.state.message}
                                            </small>
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address2">Country</label>

                                                    <select
                                                        className="form-control username-field" {...register("country", registerOptions.country)}>
                                                        <option value="US">USA</option>
                                                    </select></div>
                                            </div>
                                        </div>

                                        <small className="text-danger">
                                            {errors?.country && errors.country.message}
                                        </small>


                                        <Controller
                                            control={control}
                                            name="mobileNumber"
                                            rules={{
                                                required: {value: true, message: "Required"},
                                                minLength: {value: 11, message: "Enter a valid 10 digits USA phone"},
                                                maxLength: {value: 11, message: "Max Length"},
                                                validate: isNumber
                                            }}
                                            render={({field: {ref, ...field}}) => <PhoneInput
                                                {...field}
                                                inputExtraProps={{
                                                    ref,
                                                    required: true,
                                                    autoFocus: true
                                                }}

                                                country={'us'}
                                                onlyCountries={["us"]}
                                                countryCodeEditable={false}
                                                specialLabel={"Mobile Number"}
                                            />}
                                        />

                                        <small className="text-danger">
                                            {errors?.mobileNumber && errors.mobileNumber.message}
                                        </small>

                                        <br/>
                                        <div>How did you hear about us ?</div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="1"
                                                   id="exampleRadios1" value="1" checked={howDoYouKnowUs === '1'} onChange={onOptionChange}/>
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                &nbsp;BrushPassResearch x Farelanes Social Content
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="2"
                                                   id="exampleRadios2" value="2" checked={howDoYouKnowUs === '2'} onChange={onOptionChange}/>
                                            <label className="form-check-label" htmlFor="exampleRadios2">
                                                &nbsp;Google/Search Engine
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="3"
                                                   id="exampleRadios3" value="3" checked={howDoYouKnowUs === '3'} onChange={onOptionChange}/>
                                            <label className="form-check-label" htmlFor="exampleRadios3">
                                                &nbsp;Farelanes.com Blog
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="4"
                                                   id="exampleRadios4" value="4" checked={howDoYouKnowUs === '4'} onChange={onOptionChange}/>
                                            <label className="form-check-label" htmlFor="exampleRadios4">
                                                &nbsp;Farelanes socials 'LI/X/FB/IG/etc'
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="5"
                                                   id="exampleRadios5" value="5" checked={howDoYouKnowUs === '5'} onChange={onOptionChange}/>
                                            <label className="form-check-label" htmlFor="exampleRadios5">
                                                &nbsp;Trade show
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="6"
                                                   id="exampleRadios6" value="6" checked={howDoYouKnowUs === '6'} onChange={onOptionChange}/>
                                            <label className="form-check-label" htmlFor="exampleRadios6">
                                                &nbsp;Word of Mouth
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="7"
                                                   id="exampleRadios7" value="7" checked={howDoYouKnowUs === '7'} onChange={onOptionChange}/>
                                            <label className="form-check-label" htmlFor="exampleRadios7">
                                                &nbsp;Other
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mt-4">
                                                    <input className="button__settings-save" type="submit"
                                                           value="Save"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {(!farelanesUser || !state.showResult) &&
            <div className="text-center p-3">
                <span className="spinner-border spinner-border-lg align-center"></span>
            </div>
        }

    </section>;
};

export default withAuthenticationRequired(ProfileDetails, {
    onRedirecting: () => <Loading/>,
});
