import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";

export const GlobalConfig = () => {


    const registerOptions = {
        totalRecords: {
            required: "City is required",
            maxLength: {
                value: 108,
                message: "City cannot be more than 108 characters"
            }
        },
        startCityRadius: {
            required: "Zip is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        dropCityRadius: {
            required: "State is required",
            maxLength: {
                value: 50,
                message: "State cannot be more than 50 characters"
            }
        }

    };
// get functions to build form with useForm() hook
    const { register, handleSubmit, reset } = useForm();

    // user state for form
    const [farelanesUser, setFarelanesUser] = useState(null);

    const [state, setState] = useState({
        showResult: false,
        loading: false,
        apiMessage: 'Tickers are loading ....',
        error: null,

    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();
    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.config(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data
                    });
                    console.log(response.data)
                    setFarelanesUser({
                        response: response.data,
                        totalRecords: response.data[0].value,
                        startCityRadius: response.data[1].value,
                        dropCityRadius: response.data[2].value,
                        loading: false, })
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);




    async function onSubmit(data) {
        // display form data on submit
        const token = await getAccessTokenSilently();
        setState({
            message: "",
            loading: true
        });

        console.log("****");
        console.log(farelanesUser);
        let payload = farelanesUser.response;
        payload[0].value = data.totalRecords;
        payload[1].value = data.startCityRadius;
        payload[2].value = data.dropCityRadius;

        console.log("updating payload");


        console.log(payload);
        UserService.updateConfig(payload, token).then(
            response => {
                setState({
                    content: response.data,
                    loading: false,
                    message: 'Success!'
                });
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );


        return false;
    }
    return (
        <div className="container AppFonts">
            <h5 className="">Update Global Config here....</h5>

            <div className="">
                {farelanesUser &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="">
                            <div className="form-group col-sm-3">
                                <label>RFQ Number of records.</label>
                                <input name="firstName" type="text" {...register('totalRecords', registerOptions.totalRecords)} className="form-control AppFonts" />
                            </div>

                            <div className="form-group col-sm-3">
                                <label>RFQ Start City or Zip Radius mi.</label>
                                <input name="milesGreaterThan" type="text" {...register('startCityRadius', registerOptions.startCityRadius)} className="form-control AppFonts" />
                            </div>
                            <div className="form-group col-sm-3">
                                <label>RFQ End City or Zip Radius mi.</label>
                                <input name="lineHaulAmountGreaterThan" type="text" {...register('dropCityRadius', registerOptions.dropCityRadius)} className="form-control AppFonts" />
                            </div>

                        </div>


                        <div className="form-group">
                            <button type="submit" className="button__settings-save">

                                {state.loading && (
                                    <span className="spinner-border spinner-border-sm admin-sync-subit-spiiner"></span>
                                )}
                                &nbsp;&nbsp;<span className="">Save</span></button>
                        </div>

                        {state.content && (
                            <div className="green">
                                <div className="">
                                    <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Saved !</h5>
                                </div>
                            </div>
                        )}

                    </form>
                }
                {!farelanesUser &&
                    <div className="text-center p-3">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </div>
                }
            </div>
        </div>
    );
};

export default withAuthenticationRequired(GlobalConfig, {
    onRedirecting: () => <Loading />,
});
