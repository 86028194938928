import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import LaneService from "../services/LaneService";
import Select from "react-select";

import 'react-tooltip/dist/react-tooltip.css'
import AsyncSelect from "react-select/async";
import UserService from "../services/UserService";
import {toast, ToastContainer} from "react-toastify";
import {PageLoader} from "./page-loader";

export const TickersAndPairs = () => {

    const [state, setState] = useState({
        showResult: false,
        apiMessage: undefined,
        error: null,
    });

    const [farelanesUser, setFarelanesUser] = useState(null);

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                        farelanesUser: {firstName: 'Frank', lastName: 'Murphy'}
                    });
                    setFarelanesUser({
                        pairs: response.data.pairs,
                        tickerOne: response.data.tickerOne,
                        tickerTwo: response.data.tickerTwo,
                        // tickerOneName: response.data.tickerOneName,
                        // tickerTwoName: response.data.tickerTwoName
                    });

                    setTickerOneName(response.data.tickerOneName);
                    setTickerTwoName(response.data.tickerTwoName);
                    if (response.data.pairs) {


                        setSelectedPickCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].start);
                        setSelectedDropCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].end);
                        setDayRangeValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].days);
                        setEquipmentValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].equipment);
                        setRadiusValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].miles);

                        setSelectedPickCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].start);
                        setSelectedDropCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].end);
                        setDayRangeValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].days);
                        setEquipmentValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].equipment);
                        setRadiusValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].miles);

                        setSelectedPickCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].start);
                        setSelectedDropCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].end);
                        setDayRangeValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].days);
                        setEquipmentValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].equipment);
                        setRadiusValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].miles);


                        setSelectedPickCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].start);
                        setSelectedDropCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].end);
                        setDayRangeValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].days);
                        setEquipmentValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].equipment);
                        setRadiusValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].miles);

                        setSelectedPickCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].start);
                        setSelectedDropCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].end);
                        setDayRangeValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].days);
                        setEquipmentValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].equipment);
                        setRadiusValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].miles);

                        setSelectedPickCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].start);
                        setSelectedDropCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].end);
                        setDayRangeValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].days);
                        setEquipmentValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].equipment);
                        setRadiusValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.pairs[2].miles);


                        setSelectedPickCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].start);
                        setSelectedDropCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].end);
                        setDayRangeValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].days);
                        setEquipmentValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].equipment);
                        setRadiusValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].miles);

                        setSelectedPickCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].start);
                        setSelectedDropCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].end);
                        setDayRangeValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].days);
                        setEquipmentValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].equipment);
                        setRadiusValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].miles);

                        setSelectedPickCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].start);
                        setSelectedDropCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].end);
                        setDayRangeValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].days);
                        setEquipmentValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].equipment);
                        setRadiusValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.pairs[2].miles);


                    }
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log(''));
    }, []);


    const equipmentOptions = [
        {value: 'Auto Carrier', label: 'Auto Carrier'},
        {value: 'Container', label: 'Container'},
        {value: 'Conestoga', label: 'Conestoga'},
        {value: 'Dry Van', label: 'Dry Van'},
        {value: 'Flatbed', label: 'Flatbed'},
        {value: 'Hopper Bottom', label: 'Hopper Bottom'},
        {value: 'Lowboy', label: 'Lowboy'},
        {value: 'Double Drop', label: 'Double Drop'},
        {value: 'Maxi', label: 'Maxi'},
        {value: 'Power Only', label: 'Power Only'},
        {value: 'Pneumatic', label: 'Pneumatic'},
        {value: 'Reefer', label: 'Reefer'},
        {value: 'Straight Box', label: 'Straight Box'},
        {value: 'Step Deck', label: 'Step Deck'},
        {value: 'Sprinter', label: 'Sprinter'},
        {value: 'Tanker', label: 'Tanker'},
        {value: 'Truck and Trailer', label: 'Truck & Trailer'},
    ]

    const [tickerOneName, setTickerOneName] = useState('');
    const [tickerTwoName, setTickerTwoName] = useState('');

    const [inputPickCityValue, setPickCityValue] = useState('');
    const [inputDropCityValue, setDropCityValue] = useState('');


    const [inputPickCityValue2, setPickCityValue2] = useState('');
    const [inputDropCityValue2, setDropCityValue2] = useState('');

    const [inputPickCityValue3, setPickCityValue3] = useState('');
    const [inputDropCityValue3, setDropCityValue3] = useState('');

    const [inputPickCityValue4, setPickCityValue4] = useState('');
    const [inputDropCityValue4, setDropCityValue4] = useState('');

    const [inputPickCityValue5, setPickCityValue5] = useState('');
    const [inputDropCityValue5, setDropCityValue5] = useState('');

    const [inputPickCityValue6, setPickCityValue6] = useState('');
    const [inputDropCityValue6, setDropCityValue6] = useState('');


    const [inputPickCityValue7, setPickCityValue7] = useState('');
    const [inputDropCityValue7, setDropCityValue7] = useState('');

    const [inputPickCityValue8, setPickCityValue8] = useState('');
    const [inputDropCityValue8, setDropCityValue8] = useState('');

    const [inputPickCityValue9, setPickCityValue9] = useState('');
    const [inputDropCityValue9, setDropCityValue9] = useState('');


    const [selectedPickCityValue, setSelectedPickCityValue] = useState(null);
    const [selectedDropCityValue, setSelectedDropCityValue] = useState(null);
    const [equipmentValue, setEquipmentValue] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue, setRadiusValue] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue, setDayRangeValue] = useState({value: 15, label: '15 days'});


    const [selectedPickCityValue2, setSelectedPickCityValue2] = useState(null);
    const [selectedDropCityValue2, setSelectedDropCityValue2] = useState(null);
    const [equipmentValue2, setEquipmentValue2] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue2, setRadiusValue2] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue2, setDayRangeValue2] = useState({value: 15, label: '15 days'});


    const [selectedPickCityValue3, setSelectedPickCityValue3] = useState(null);
    const [selectedDropCityValue3, setSelectedDropCityValue3] = useState(null);
    const [equipmentValue3, setEquipmentValue3] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue3, setRadiusValue3] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue3, setDayRangeValue3] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue4, setSelectedPickCityValue4] = useState(null);
    const [selectedDropCityValue4, setSelectedDropCityValue4] = useState(null);
    const [equipmentValue4, setEquipmentValue4] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue4, setRadiusValue4] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue4, setDayRangeValue4] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue5, setSelectedPickCityValue5] = useState(null);
    const [selectedDropCityValue5, setSelectedDropCityValue5] = useState(null);
    const [equipmentValue5, setEquipmentValue5] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue5, setRadiusValue5] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue5, setDayRangeValue5] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue6, setSelectedPickCityValue6] = useState(null);
    const [selectedDropCityValue6, setSelectedDropCityValue6] = useState(null);
    const [equipmentValue6, setEquipmentValue6] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue6, setRadiusValue6] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue6, setDayRangeValue6] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue7, setSelectedPickCityValue7] = useState(null);
    const [selectedDropCityValue7, setSelectedDropCityValue7] = useState(null);
    const [equipmentValue7, setEquipmentValue7] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue7, setRadiusValue7] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue7, setDayRangeValue7] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue8, setSelectedPickCityValue8] = useState(null);
    const [selectedDropCityValue8, setSelectedDropCityValue8] = useState(null);
    const [equipmentValue8, setEquipmentValue8] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue8, setRadiusValue8] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue8, setDayRangeValue8] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue9, setSelectedPickCityValue9] = useState(null);
    const [selectedDropCityValue9, setSelectedDropCityValue9] = useState(null);
    const [equipmentValue9, setEquipmentValue9] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue9, setRadiusValue9] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue9, setDayRangeValue9] = useState({value: 15, label: '15 days'});


    const radiusOptions = [
        {value: 50, label: '50 miles'},
        {value: 100, label: '100 miles'},
        {value: 150, label: '150 miles'},
        {value: 200, label: '200 miles'}
    ]

    const dayRangeOptions = [
        {value: 15, label: '15 days'},
        {value: 7, label: '7 days'},
        {value: 3, label: '3 days'},
    ]


    const onChangeTickerOneName = (event) => {
        setTickerOneName(event.target.value);
    };

    const onChangeTickerTwoName = (event) => {
        setTickerTwoName(event.target.value);
    };
    // handle input change event ONE
    const handleEquipmentChange = (event) => {
        setEquipmentValue(event);
    };

    const handleRadiusChange = (event) => {
        setRadiusValue(event);
    };

    const handleDayRangeValueChange = value => {
        setDayRangeValue(value);
    };
    const handlePickCityInputChange = value => {
        setPickCityValue(value);
    };

    // handle selection
    const handlePickCityChange = value => {
        setSelectedPickCityValue(value);
    }

    const handleDropCityInputChange = value => {
        setDropCityValue(value);
    };

    // handle selection
    const handleDropCityChange = value => {
        setSelectedDropCityValue(value);
    }

    //// handle input change event ONE END


    //TWO

    const handleEquipmentChange2 = (event) => {
        setEquipmentValue2(event);
    };

    const handleRadiusChange2 = (event) => {
        setRadiusValue2(event);
    };

    const handleDayRangeValueChange2 = value => {
        setDayRangeValue2(value);
    };
    const handlePickCityInputChange2 = value => {
        setPickCityValue2(value);
    };

    // handle selection
    const handlePickCityChange2 = value => {
        setSelectedPickCityValue2(value);
    }

    const handleDropCityInputChange2 = value => {
        setDropCityValue2(value);
    };

    // handle selection
    const handleDropCityChange2 = value => {
        setSelectedDropCityValue2(value);
    }

    //TWO end


    //THREE

    const handleEquipmentChange3 = (event) => {
        setEquipmentValue3(event);
    };

    const handleRadiusChange3 = (event) => {
        setRadiusValue3(event);
    };

    const handleDayRangeValueChange3 = value => {
        setDayRangeValue3(value);
    };
    const handlePickCityInputChange3 = value => {
        setPickCityValue3(value);
    };

    // handle selection
    const handlePickCityChange3 = value => {
        setSelectedPickCityValue3(value);
    }

    const handleDropCityInputChange3 = value => {
        setDropCityValue3(value);
    };

    // handle selection
    const handleDropCityChange3 = value => {
        setSelectedDropCityValue3(value);
    }

    //THREE end

    //FOUR

    const handleEquipmentChange4 = (event) => {
        setEquipmentValue4(event);
    };

    const handleRadiusChange4 = (event) => {
        setRadiusValue4(event);
    };

    const handleDayRangeValueChange4 = value => {
        setDayRangeValue4(value);
    };
    const handlePickCityInputChange4 = value => {
        setPickCityValue4(value);
    };

    // handle selection
    const handlePickCityChange4 = value => {
        setSelectedPickCityValue4(value);
    }

    const handleDropCityInputChange4 = value => {
        setDropCityValue4(value);
    };

    // handle selection
    const handleDropCityChange4 = value => {
        setSelectedDropCityValue4(value);
    }

    //FOUR end

    //FIVE

    const handleEquipmentChange5 = (event) => {
        setEquipmentValue5(event);
    };

    const handleRadiusChange5 = (event) => {
        setRadiusValue5(event);
    };

    const handleDayRangeValueChange5 = value => {
        setDayRangeValue5(value);
    };
    const handlePickCityInputChange5 = value => {
        setPickCityValue5(value);
    };

    // handle selection
    const handlePickCityChange5 = value => {
        setSelectedPickCityValue5(value);
    }

    const handleDropCityInputChange5 = value => {
        setDropCityValue5(value);
    };

    // handle selection
    const handleDropCityChange5 = value => {
        setSelectedDropCityValue5(value);
    }

    //FIVE end

    //SIX

    const handleEquipmentChange6 = (event) => {
        setEquipmentValue6(event);
    };

    const handleRadiusChange6 = (event) => {
        setRadiusValue6(event);
    };

    const handleDayRangeValueChange6 = value => {
        setDayRangeValue6(value);
    };
    const handlePickCityInputChange6 = value => {
        setPickCityValue6(value);
    };

    // handle selection
    const handlePickCityChange6 = value => {
        setSelectedPickCityValue6(value);
    }

    const handleDropCityInputChange6 = value => {
        setDropCityValue6(value);
    };

    // handle selection
    const handleDropCityChange6 = value => {
        setSelectedDropCityValue6(value);
    }

    //FISIXVE end

    //SEVEN

    const handleEquipmentChange7 = (event) => {
        setEquipmentValue7(event);
    };

    const handleRadiusChange7 = (event) => {
        setRadiusValue7(event);
    };

    const handleDayRangeValueChange7 = value => {
        setDayRangeValue7(value);
    };
    const handlePickCityInputChange7 = value => {
        setPickCityValue7(value);
    };

    // handle selection
    const handlePickCityChange7 = value => {
        setSelectedPickCityValue7(value);
    }

    const handleDropCityInputChange7 = value => {
        setDropCityValue7(value);
    };

    // handle selection
    const handleDropCityChange7 = value => {
        setSelectedDropCityValue7(value);
    }

    //SEVEN end

    //EIGHT

    const handleEquipmentChange8 = (event) => {
        setEquipmentValue8(event);
    };

    const handleRadiusChange8 = (event) => {
        setRadiusValue8(event);
    };

    const handleDayRangeValueChange8 = value => {
        setDayRangeValue8(value);
    };
    const handlePickCityInputChange8 = value => {
        setPickCityValue8(value);
    };

    // handle selection
    const handlePickCityChange8 = value => {
        setSelectedPickCityValue8(value);
    }

    const handleDropCityInputChange8 = value => {
        setDropCityValue8(value);
    };

    // handle selection
    const handleDropCityChange8 = value => {
        setSelectedDropCityValue8(value);
    }

    //EIGHT end

    //NINE

    const handleEquipmentChange9 = (event) => {
        setEquipmentValue9(event);
    };

    const handleRadiusChange9 = (event) => {
        setRadiusValue9(event);
    };

    const handleDayRangeValueChange9 = value => {
        setDayRangeValue9(value);
    };
    const handlePickCityInputChange9 = value => {
        setPickCityValue9(value);
    };

    // handle selection
    const handlePickCityChange9 = value => {
        setSelectedPickCityValue9(value);
    }

    const handleDropCityInputChange9 = value => {
        setDropCityValue9(value);
    };

    // handle selection
    const handleDropCityChange9 = value => {
        setSelectedDropCityValue9(value);
    }

    //NINE end


    // load options using API call
    const loadOptions = async (inputValue) => {
        const token = await getAccessTokenSilently();
        return LaneService.getCityOptions(inputValue, token).then(
            response => {
                return response;
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );
    };

    async function save_pairs() {
        let payload = {};

        payload.sub = user.sub;
        payload.tickerOneName = tickerOneName;
        payload.tickerTwoName = tickerTwoName;
        payload.pairs = [
            {

                start: selectedPickCityValue,
                end: selectedDropCityValue,
                days: dayRangeValue,
                equipment: equipmentValue,
                miles: radiusValue
            },
            {
                start: selectedPickCityValue2,
                end: selectedDropCityValue2,
                days: dayRangeValue2,
                equipment: equipmentValue2,
                miles: radiusValue2
            },
            {
                start: selectedPickCityValue3,
                end: selectedDropCityValue3,
                days: dayRangeValue3,
                equipment: equipmentValue3,
                miles: radiusValue3
            }
        ];

        payload.tickerOne = [
            {
                start: selectedPickCityValue4,
                end: selectedDropCityValue4,
                days: dayRangeValue4,
                equipment: equipmentValue4,
                miles: radiusValue4
            },
            {
                start: selectedPickCityValue5,
                end: selectedDropCityValue5,
                days: dayRangeValue5,
                equipment: equipmentValue5,
                miles: radiusValue5
            },
            {
                start: selectedPickCityValue6,
                end: selectedDropCityValue6,
                days: dayRangeValue6,
                equipment: equipmentValue6,
                miles: radiusValue6
            }
        ];


        payload.tickerTwo = [
            {
                start: selectedPickCityValue7,
                end: selectedDropCityValue7,
                days: dayRangeValue7,
                equipment: equipmentValue7,
                miles: radiusValue7
            },
            {
                start: selectedPickCityValue8,
                end: selectedDropCityValue8,
                days: dayRangeValue8,
                equipment: equipmentValue8,
                miles: radiusValue8
            },
            {
                start: selectedPickCityValue9,
                end: selectedDropCityValue9,
                days: dayRangeValue9,
                equipment: equipmentValue9,
                miles: radiusValue9
            }
        ];
        const token = await getAccessTokenSilently();
        UserService.putUserTickersAndPairs(payload, token).then(
            response => {
                setState({
                    ...state,
                    showResult: true,
                    apiMessage: response.data,
                    farelanesUser: {firstName: 'Frank', lastName: 'Murphy'}
                });
                setFarelanesUser({
                    pairs: response.data.pairs,
                });
                if (response.data.pairs) {


                    setSelectedPickCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].start);
                    setSelectedDropCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].end);
                    setDayRangeValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].days);
                    setEquipmentValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].equipment);
                    setRadiusValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].miles);

                    setSelectedPickCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].start);
                    setSelectedDropCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].end);
                    setDayRangeValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].days);
                    setEquipmentValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].equipment);
                    setRadiusValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].miles);

                    setSelectedPickCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].start);
                    setSelectedDropCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].end);
                    setDayRangeValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].days);
                    setEquipmentValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].equipment);
                    setRadiusValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].miles);


                    setSelectedPickCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].start);
                    setSelectedDropCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].end);
                    setDayRangeValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].days);
                    setEquipmentValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].equipment);
                    setRadiusValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].miles);

                    setSelectedPickCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].start);
                    setSelectedDropCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].end);
                    setDayRangeValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].days);
                    setEquipmentValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].equipment);
                    setRadiusValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].miles);

                    setSelectedPickCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].start);
                    setSelectedDropCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].end);
                    setDayRangeValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].days);
                    setEquipmentValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].equipment);
                    setRadiusValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.pairs[2].miles);


                    setSelectedPickCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].start);
                    setSelectedDropCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].end);
                    setDayRangeValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].days);
                    setEquipmentValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].equipment);
                    setRadiusValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].miles);

                    setSelectedPickCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].start);
                    setSelectedDropCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].end);
                    setDayRangeValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].days);
                    setEquipmentValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].equipment);
                    setRadiusValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].miles);

                    setSelectedPickCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].start);
                    setSelectedDropCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].end);
                    setDayRangeValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].days);
                    setEquipmentValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].equipment);
                    setRadiusValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.pairs[2].miles);


                }
                toast("Pairs and Tickers updated successfully.");
            },
            error => {

            }
        )
    }

    return (
        <>
            <div className="form-group">
                <ToastContainer></ToastContainer>
                <div className="container AppFonts_Tickers">
                    <fieldset className="the-fieldset-profile border-dark">

                        <div className="profile-pairs">
                            {/*<legend className="the-legend font-weight-bold">Your TOP 3 Routes.</legend>*/}
                            <h3>Your TOP 3 routes</h3>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue}
                                        onChange={handleEquipmentChange}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange}
                                        onChange={handlePickCityChange}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange}
                                        onChange={handleDropCityChange}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue}
                                        onChange={handleRadiusChange}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue}
                                        onChange={handleDayRangeValueChange}
                                    />
                                </div>


                            </div>
                            <br/>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue2}
                                        onChange={handleEquipmentChange2}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue2}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange2}
                                        onChange={handlePickCityChange2}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue2}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange2}
                                        onChange={handleDropCityChange2}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue2}
                                        onChange={handleRadiusChange2}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue2}
                                        onChange={handleDayRangeValueChange2}
                                    />
                                </div>


                            </div>
                            <br/>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue3}
                                        onChange={handleEquipmentChange3}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue3}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange3}
                                        onChange={handlePickCityChange3}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue3}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange3}
                                        onChange={handleDropCityChange3}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue3}
                                        onChange={handleRadiusChange3}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue3}
                                        onChange={handleDayRangeValueChange3}
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-4">
                                    <input className="button__settings-save" type="submit"
                                           onClick={save_pairs}
                                           value="Save"/>
                                </div>
                            </div>
                        </div>
                        <hr className="fhr"/>
                        <div className="profile-tickerOne">

                            <h3>Ticker 1
                                - {tickerOneName}</h3>


                            <div className="form-row">
                                <div className="col-md-6"><label className="labels">Ticker 1
                                    Name</label>

                                    <input type="text"
                                           name="tockerOneName"
                                           value={tickerOneName}
                                           onChange={onChangeTickerOneName}
                                           className="form-control"
                                           placeholder="Ticker 1"
                                    ></input>

                                </div>
                            </div>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue4}
                                        onChange={handleEquipmentChange4}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue4}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange4}
                                        onChange={handlePickCityChange4}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue4}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange4}
                                        onChange={handleDropCityChange4}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue4}
                                        onChange={handleRadiusChange4}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue4}
                                        onChange={handleDayRangeValueChange4}
                                    />
                                </div>


                            </div>
                            <br/>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue5}
                                        onChange={handleEquipmentChange5}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue5}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange5}
                                        onChange={handlePickCityChange5}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue5}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange5}
                                        onChange={handleDropCityChange5}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue5}
                                        onChange={handleRadiusChange5}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue5}
                                        onChange={handleDayRangeValueChange5}
                                    />
                                </div>


                            </div>
                            <br/>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue6}
                                        onChange={handleEquipmentChange6}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue6}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange6}
                                        onChange={handlePickCityChange6}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue6}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange6}
                                        onChange={handleDropCityChange6}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue6}
                                        onChange={handleRadiusChange6}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue6}
                                        onChange={handleDayRangeValueChange6}
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-4">
                                    <input className="button__settings-save" type="submit"
                                           onClick={save_pairs}
                                           value="Save"/>
                                </div>
                            </div>
                        </div>
                        <hr className="fhr"/>
                        <div className="profile-tickerTwo">

                            <h3>Ticker 2
                                - {tickerTwoName}</h3>

                            <div className="form-row">
                                <div className="col-md-6"><label className="labels">Ticker 2
                                    Name</label>

                                    <input type="text"
                                           name="tickerTwoName"
                                           value={tickerTwoName}
                                           onChange={onChangeTickerTwoName}
                                           className="form-control"
                                           placeholder="Ticker 2"
                                    ></input>

                                </div>
                            </div>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue7}
                                        onChange={handleEquipmentChange7}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue7}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange7}
                                        onChange={handlePickCityChange7}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue7}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange7}
                                        onChange={handleDropCityChange7}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue7}
                                        onChange={handleRadiusChange7}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue7}
                                        onChange={handleDayRangeValueChange7}
                                    />
                                </div>


                            </div>
                            <br/>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue8}
                                        onChange={handleEquipmentChange8}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue8}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange8}
                                        onChange={handlePickCityChange8}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue8}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange8}
                                        onChange={handleDropCityChange8}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue8}
                                        onChange={handleRadiusChange8}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue8}
                                        onChange={handleDayRangeValueChange8}
                                    />
                                </div>


                            </div>
                            <br/>
                            <div className="form-row">

                                {/*Equipment Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Equipment Type</b></label>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        value={equipmentValue9}
                                        onChange={handleEquipmentChange9}
                                    />
                                </div>
                                {/*Pick City Select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Pick City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedPickCityValue9}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handlePickCityInputChange9}
                                        onChange={handlePickCityChange9}
                                    />
                                </div>

                                {/*Drop City select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Drop City</b></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedDropCityValue9}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={handleDropCityInputChange9}
                                        onChange={handleDropCityChange9}
                                    />
                                </div>

                                {/*Radius select*/}
                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Radius</b></label>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        value={radiusValue9}
                                        onChange={handleRadiusChange9}
                                    />
                                </div>

                                <div className="col-sm">
                                    <label htmlFor="exampleFormControlFile1"><b>Day
                                        Range</b></label>
                                    <Select
                                        label="What radius"
                                        options={dayRangeOptions}
                                        value={dayRangeValue9}
                                        onChange={handleDayRangeValueChange9}
                                    />
                                </div>


                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <div className="mt-4">
                                    <input className="button__settings-save" type="submit"
                                           onClick={save_pairs}
                                           value="Save"/>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </div>
            </div>
        </>
    );
};

export default withAuthenticationRequired(TickersAndPairs, {
    onRedirecting: () => <PageLoader/>,
});

