import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {PageLoader} from "./components/page-loader";
import {AuthenticationGuard} from "./components/authentication-guard";
import {AdminPage} from "./pages/admin-page";
import {CallbackPage} from "./pages/callback-page";
import {HomePage} from "./pages/home-page";
import {NotFoundPage} from "./pages/not-found-page";
import {ProfilePage} from "./pages/profile-page";
import {ProtectedPage} from "./pages/protected-page";
import {PublicPage} from "./pages/public-page";
import {TopLanes} from "./components/TopLanes";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Rates from "./pages/Rates";
import {SubscriptionVerify} from "./pages/SubscriptionVerify";
import {Insights} from "./pages/Insights";
import {UserVerificationJS} from "./components/UserVerifiyMe";
import Administration from "./pages/Administration";
import DragDrop from "./components/drag-drop";
import FileUploadProcessor from "./pages/lane-data-file-upload";

export const App = () => {
    const {isLoading} = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader/>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route
                path="/profile"
                element={<AuthenticationGuard component={ProfilePage}/>}
            />
            <Route path="/public" element={<PublicPage/>}/>
            <Route
                path="/protected"
                element={<AuthenticationGuard component={ProtectedPage}/>}
            />
            <Route
                path="/admin"
                element={<AuthenticationGuard component={Administration}/>}
            />
            <Route path="/user-verify" element={<AuthenticationGuard component={UserVerificationJS}/>}/>

            <Route path="/top-lanes" element={<AuthenticationGuard component={TopLanes}/>}/>
            <Route path="/insights" element={<AuthenticationGuard component={Insights}/>}/>
            <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard}/>}/>
            <Route path="/settings" element={<AuthenticationGuard component={Profile}/>}/>
            <Route path="/rates" element={<AuthenticationGuard component={Rates}/>}/>
            <Route path="/callback" element={<CallbackPage/>}/>
            <Route path="/subscription-refresh" element={<SubscriptionVerify/>}/>
            <Route path="/rfq" element={<FileUploadProcessor/>}/>

            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    );
};
