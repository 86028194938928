import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "reactstrap";
import {useForm} from 'react-hook-form';
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
export const MCNumberAndALEI = () => {
    // const {register, handleSubmit, formState: {errors}, reset} = useForm();

    let navigate = useNavigate();

    const registerOptions = {
        motorCarrierNumber: {
            required: "MC Number is required",
            maxLength: {
                value: 50,
                message: "MC Number cannot be more than 50 characters"
            }
        },
        dotNumber: {
            required: "Dot Number is required",
            maxLength: {
                value: 50,
                message: "Dot Number cannot be more than 30 characters"
            }
        },
        aleiNumber: {
            required: "Alei Number is required",
            maxLength: {
                value: 50,
                message: "Alei Number cannot be more than 50 characters"
            }
        },
        smartPrefixField: {
            required: "Smart Prefix is required",
            maxLength: {
                value: 50,
                message: "Smart Prefix cannot be more than 50 characters"
            }
        },
        subPrefixField: {
            required: "Sub Prefix is required",
            maxLength: {
                value: 50,
                message: "Sub Prefix cannot be more than 50 characters"
            }
        },
        dnbField: {
            required: "DNB Field is required",
            maxLength: {
                value: 50,
                message: "DNB cannot be more than 50 characters"
            }
        }
    };

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
    });

    const [farelanesUser, setFarelanesUser] = useState(null);

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                        farelanesUser: {firstName: 'Frank', lastName: 'Murphy'}
                    });
                    setFarelanesUser({
                        motorCarrierNumber: response.data.motorCarrierNumber,
                        dotNumber: response.data.dotNumber,
                        aleiNumber: response.data.aleiNumber,
                        smartPrefixField: response.data.smartPrefixField,
                        subPrefixField: response.data.subPrefixField,
                        dnbField: response.data.dnbField
                    })
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    async function onSubmit(data) {
        // display form data on submit
        const token = await getAccessTokenSilently();
        setState({
            ...state,
            showResult: false
        });
        UserService.putUserMCALEI({
            motorCarrierNumber: data.motorCarrierNumber,
            dotNumber: data.dotNumber,
            aleiNumber: data.aleiNumber,
            smartPrefixField: data.smartPrefixField,
            subPrefixField: data.subPrefixField,
            dnbField: data.dnbField,
            sub: user.sub
        }, token).then(response => {
            setState({
                ...state,
                showResult: true
            });
            toast("User updated successfully.");
            navigate("/subscription-refresh",{state:{refresh:true}});
        })
        return true;
    }


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);

    return (<section className="AppFonts_Tickers">
            <ToastContainer />
        {farelanesUser &&
            <div className="">
                <div className="">
                    <div className="row ">
                        <div className="col-12 col-lg-9 col-xl-7">
                            <div className="card">
                                <div className="card-body p-4 p-md-5">
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        {/*First Name and Last Name*/}
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="firstName">Motor Carrier
                                                        Number
                                                    </label>
                                                    <input className="form-control username-field"
                                                           placeholder={"MC Number"} {...register("motorCarrierNumber")} />
                                                </div>

                                                <small className="text-danger">
                                                    {errors?.motorCarrierNumber && errors.motorCarrierNumber.message}
                                                </small>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="lastName">DOT Number</label>
                                                    <input
                                                        className="form-control username-field" {...register("dotNumber")} />
                                                </div>
                                                <small className="text-danger">
                                                    {errors?.dotNumber && errors.dotNumber.message}
                                                </small>
                                            </div>
                                        </div>
                                        {/*-end-First Name and Last Name*/}





                                        {/*City Line 1 and Zip*/}
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address2">DNB Field</label>
                                                    <input
                                                        className="form-control username-field" {...register("dnbField")} />
                                                </div>
                                                <small className="text-danger">
                                                    {errors?.dnbField && errors.dnbField.message}
                                                </small>
                                            </div>

                                        </div>


                                        {/*Address Line 1 and Address Line 2*/}
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address1">ALEI*</label>
                                                    <input className="form-control username-field"
                                                           placeholder={"ALEI"} {...register("aleiNumber")} />
                                                </div>
                                                <small className="text-danger">
                                                    {errors?.aleiNumber && errors.aleiNumber.message}
                                                </small>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address2">Smart Prefix
                                                        Field*</label>
                                                    <input className="form-control username-field"
                                                           placeholder={"Smart Prefix"} {...register("smartPrefixField")} />
                                                </div>
                                                <small className="text-danger">
                                                    {errors?.smartPrefixField && errors.smartPrefixField.message}
                                                </small>
                                            </div>
                                        </div>
                                        {/*-end-Address Line 1 and Address Line 2e*/}


                                        {/*City Line 1 and Zip*/}
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <label className="form-label" htmlFor="address1">Sub Prefix
                                                        Field*</label>
                                                    <input className="form-control username-field"
                                                           placeholder={"Sub Prefix"} {...register("subPrefixField")} />
                                                </div>
                                                <small className="text-danger">
                                                    {errors?.subPrefixField && errors.subPrefixField.message}
                                                </small>
                                            </div>

                                        </div>
                                        {/*-end-City Line 1 and Zip*/}


                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mt-4">
                                                    <input className="button__settings-save" type="submit"
                                                           value="save"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
            {(!farelanesUser || !state.showResult) &&
                <div className="text-center p-3">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
        </section>
    );
};

export default withAuthenticationRequired(MCNumberAndALEI, {
    onRedirecting: () => <Loading/>,
});
