import {API_BASE_URL} from "../common/ApiUrl";
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";


class LaneService {

    async getTopLanes(token) {
        return axios.get(API_BASE_URL + 'top_routes', {headers: {Authorization: `Bearer ${token}`}});
    }

    getLaneStatistics(pick_city, pick_state, drop_city, drop_state, equipment, radius, days, token) {
        return axios.get(API_BASE_URL + `lane-statistics?pick_city=${pick_city}&drop_city=${drop_city}&pick_state=${pick_state}&drop_state=${drop_state}&equipment=${equipment}&radius=${radius}&pickcityradius=${days}`, {headers: {Authorization: `Bearer ${token}`}});
    }

    getCityOptions(query, token) {
        return axios.get(API_BASE_URL + `city_search?q=${query}`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

    getUserRateSearchOptions(token) {
        return axios.get(API_BASE_URL + `rate_search`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }


    getCityOptionsTwo(query, token) {
        return axios.get(API_BASE_URL + `city_top_routes`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

}

export default new LaneService();



