import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {CSVLink} from "react-csv";
import dayjs from "dayjs";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';

export const AdminLaneDataSearch = () => {
// get functions to build form with useForm() hook
    const { register, handleSubmit, reset } = useForm();
    const financial = (x) => Number.parseFloat(x).toFixed(2);
    // user state for form
    const [farelanesUser, setFarelanesUser] = useState(null);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [equipmentValue, setEquipmentValue] = useState(  [
        {value: 'apex', label: 'Apex'},
        {value: 'integra', label: 'Integra'},
    ]);
    const [equipmentOptions, setEquipmentOptions] = useState( {value: 'apex', label: 'Apex'});
    const columns = [
        {
            name: 'Pick City',
            selector: row => row.key.pickCity + ', ' + row.key.pickState,
            sortable: true,
        },

        {
            name: 'Drop City',
            selector: row => row.key.endCity + ', ' + row.key.endState,
            sortable: true,
        },

        {
            id: 'pairs',
            name: '#.Pairs',
            selector: row => row.recordPairs,
            descending: true,
            sortable: true,
        },

        {
            id: 'lane',
            name: 'Line Haul Rate',
            selector: row => '$' + financial(row.totalLaneAmount),
            sortable: true,
        },
        {
            id: 'lane',
            name: 'Average Line Haul Rate',
            selector: row => '$' + financial(row.totalLaneAmount / row.recordPairs),
            sortable: true
        }
    ];

    function handleEquipmentChange(value) {
        setEquipmentOptions(value);
    };

    async function handleSearch() {
        const token = await getAccessTokenSilently();

        let text = ''
        for (let i = 0; i < equipmentValue.length; i++) {
            text += equipmentValue[i].value + ",";
        }

        setState({
            message: "loading..",
            loading: true
        });

        UserService.doGeneralDataBigSearch(startDate, endDate, text, token).then(
            response => {
                setData(response.data);
                setState({
                    // content: response.data,
                    loading: false,
                    message: 'Success!'
                });
                if (response.data.statistic.length == 0) {
                    setState({
                        // content: response.data,
                        message: 'No records found!',
                    });
                }
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );
    }


    const [data, setData] = useState(new Date());
    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,

        // startDate: new Date(),
        // endDate: new Date(),
        content: '',
        message: '',

    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();
    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data
                    });
                    setFarelanesUser({ firstName: response.data.auth0GivenName, lastName: response.data.auth0FamilyName })
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);




    function onSubmit(data) {
        // display form data on submit
        return false;
    }
    return (
        <div className="card m-3 AppFonts">
            <h5 className="card-header">Search - Lane Data Pairs by Date Range</h5>
            <div className="card-body">
                {farelanesUser &&
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-row">

                            <div className="col-sm-2 small">
                                <label htmlFor="exampleFormControlFile1"><b>Source</b></label>
                                <Select
                                    isMulti={true}
                                    label="What source"
                                    options={equipmentValue}
                                    value={equipmentOptions}
                                    onChange={handleEquipmentChange}
                                />
                            </div>
                            <div className="border-top mt-1 col-sm-2">
                                <label htmlFor="exampleFormControlFile1"><b>Pick Date (Start)</b></label>
                                <DatePicker showIcon value={startDate} selected={startDate}
                                            onChange={(date) => setStartDate(date)}/>

                                {/*<DatePicker selected={state.startDate} onChange={(date) => setStartDate(date)} />*/}
                            </div>
                            <div className="border-top mt-1 col-sm-2">
                                <label htmlFor="exampleFormControlFile1"><b>Pick Date (End)</b></label>
                                <DatePicker showIcon value={endDate} selected={endDate}
                                            onChange={(date) => setEndDate(date)}/>

                                {/*<DatePicker selected={state.endDate} onChange={(dated) => setEndDate(dated)} />*/}
                            </div>

                            <div className="col-lg m-auto">
                                {/*<label htmlFor="exampleFormControlFile1"><b>[General]</b></label>*/}

                                <button
                                    className="button__settings-save lane-data-button"
                                    onClick={handleSearch}
                                    disabled={state.loading}
                                >
                                    {state.loading && (
                                        <span className="spinner-grow"></span>
                                    )}
                                    <span>&nbsp;Search&nbsp;</span>

                                </button>
                                <br/>
                            </div>
                            <hr/>
                        </div>


                    </form>
                }

                    <div className="container">
                        {/*<CSVLink data={this.state.data.statistic} headers={this.state.headers}><b> Export*/}
                        {/*    Data</b></CSVLink>*/}
                        <hr/>
                        <header className="border-dark">
                            <DataTable
                                striped={true}
                                columns={columns}
                                data={data.statistic}
                                defaultSortFieldId="pairs"
                                direction="desc"
                                fixedHeaderScrollHeight="300px"
                                pagination
                                responsive
                                subHeaderAlign="right"
                                subHeaderWrap
                                defaultSortAsc={false}
                            />
                        </header>
                    </div>


                {!farelanesUser &&
                    <div className="text-center p-3">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </div>
                }
            </div>
        </div>
    );
};

export default withAuthenticationRequired(AdminLaneDataSearch, {
    onRedirecting: () => <Loading />,
});
