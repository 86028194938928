import React, {Fragment} from "react";
import {TopLanes} from "../components/TopLanes";
import {CitySearch} from "../components/CitySearch";
import {PageLayout} from "../components/page-layout";

const Rates = () => (
    <PageLayout>
        <CitySearch></CitySearch>
    </PageLayout>
);

export default Rates;
