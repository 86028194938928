import React, {useEffect, useState} from "react";
import { Button, Alert } from "reactstrap";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import LaneService from "../services/LaneService";
import UserService from "../services/UserService";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {formatValue} from "react-currency-input-field";
import Progress from "./Progress";
import {PageLoader} from "./page-loader";

export const LaneData = () => {

    const [state, setState] = useState({
        showResult: false,
        apiMessage: undefined,
        error: null,
        subscription: false,
        subscriptionType: 'Default'
    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
            const token = await getAccessTokenSilently();
            UserService.userSubscriptionStatusPair(token,user).then(
                response => {
                    state.subscriptionType = response.data.subscriptionType;
                    setState({
                        ...state,
                        subscription: response.data.status,
                        subscriptionType: response.data.subscriptionType
                    })

                    UserService.getLaneData(token, user).then(
                        response=>{
                            setState({
                                ...state,
                                showResult: true,
                                apiMessage: response.data,
                            });
                        }
                    );
                },
                error => {

                }
            );

    };

    useEffect(() => {

        // declare the data fetching function
        callApi();

    }, []);

    return (
        <>
            {!state.showResult && (<div><PageLoader></PageLoader></div>)}
            <div className={"container-fluid AppFonts"}>
                <div className="row the-fieldset-lane-price">

                    {state.showResult && state.apiMessage.map(home => <div className="col-sm-4">{
                        <div>
                            <div className="fixed">
                                <div className={
                                    home != undefined && home.queryCurrentStatistic != undefined && home.queryCurrentStatistic.costPerMile.toString() == '0'
                                        ? "card  special-card"
                                        : "card empty-background"
                                }
                                >
                                    <div className="card-body ">
                                        {home != undefined && home.queryCurrentStatistic != undefined && <a data-tooltip-id="fifteenDaysAverage" data-tooltip-html=
                                            {
                                                'No.of.stops - low ' + home.queryCurrentStatistic.lowNumberOfStops + ' high ' + home.queryCurrentStatistic.highNumberOFStops + '<br/>' +
                                                'Lead time - low' + home.queryCurrentStatistic.lowLeadTime + ' high ' + home.queryCurrentStatistic.highLeadTime + '<br/>' +
                                                'Fuel surcharge - low' + home.queryCurrentStatistic.lowFuelSurcharge + ' high ' + home.queryCurrentStatistic.highFuelSurcharge + '<br/>' +
                                                'Lumper - low' + home.queryCurrentStatistic.lowLumper + ' high ' + home.queryCurrentStatistic.highLumper + '<br/>' +
                                                'Pallet charge - low' + home.queryCurrentStatistic.lowPalletCharge + ' high ' + home.queryCurrentStatistic.highPalletCharge + '<br/>' +
                                                'Weight ticket - low' + home.queryCurrentStatistic.lowWeightTicket + ' high ' + home.queryCurrentStatistic.highWeightTicket + '<br/>' +
                                                'Interchange - low' + home.queryCurrentStatistic.lowInterchange + ' high ' + home.queryCurrentStatistic.highInterchange + '<br/>' +
                                                'Container fee - low' + home.queryCurrentStatistic.lowContainerFee + ' high ' + home.queryCurrentStatistic.highContainerFee + '<br/>' +
                                                'Truck order - low' + home.queryCurrentStatistic.lowTruckOrder + ' high ' + home.queryCurrentStatistic.highTruckOrder + '<br/>' +
                                                'Unloading fees - low' + home.queryCurrentStatistic.lowUnloadingFees + ' high ' + home.queryCurrentStatistic.highUnloadingFees + '<br/>' +
                                                'Detention - low' + home.queryCurrentStatistic.lowDetention + ' high ' + home.queryCurrentStatistic.highDetention + '<br/>'
                                            }>
                                            <h5>{home.startCity} <span>&#8594;&nbsp;</span>
                                                {home.endCity}</h5>
                                            {home.queryCurrentStatistic.equipment}, {home.queryCurrentStatistic.days} days
                                            <hr/>
                                        </a>
                                        }
                                        {home != undefined && home.queryCurrentStatistic != undefined &&
                                            <div>
                                                <div>
                                                    {state.subscriptionType == 'Gold' &&
                                                    <ReactTooltip id="fifteenDaysAverage" multiline={true}/>
                                                    }

                                                </div>
                                                <h4>{formatValue({
                                                    value: home.queryCurrentStatistic.costPerMile.toString(),
                                                    intlConfig: {locale: 'en-US', currency: 'USD'},
                                                    decimalScale: 2,
                                                    suffix: '/mile'
                                                })}</h4>

                                                <h4>{formatValue({
                                                    value: home.queryCurrentStatistic.averageLoadRate.toString(),
                                                    intlConfig: {locale: 'en-US', currency: 'USD'},
                                                    decimalScale: 0,
                                                    suffix: '/load'
                                                })}</h4>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    } </div>)}
                </div>
            </div>
        </>
    );
};

export default withAuthenticationRequired(LaneData, {
    onRedirecting: () => <Loading />,
});
