import React, {Fragment, useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loading from "../components/Loading";
import {ProfileDetails} from "../components/ProfileDetails";
import {MCNumberAndALEI} from "../components/MCNumberAndALEI";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {TickersAndPairs} from "../components/TickersAndPairs";
import Subscriptions from "./Subscriptions";
import UserService from "../services/UserService";
import {PageLayout} from "../components/page-layout";
import {PageLoader} from "../components/page-loader";


export const ProfileComponent = () => {
    const {user, getAccessTokenSilently} = useAuth0();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
        response: undefined
    });

    let userNavigationDecider = {};


    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.userSettingsFlow(token, user).then(
                response => {
                    console.log(response);
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                        response: response.data
                    });
                    userNavigationDecider = response.data

                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    return (
        <>
            <PageLayout>
                {state.showResult &&

                    <div className="container">

                        <Tabs defaultIndex={state.response.defaultIndex} onSelect={(index) => console.log(index)}>
                            <div></div>
                            <TabList className="profile-tabslist">
                                <Tab disabled={state.response.screenOne}>Profile</Tab>
                                <Tab disabled={state.response.screenTwo}>MC Number & ALEI</Tab>
                                <Tab disabled={state.response.screenThree}>Subscriptions</Tab>
                                <Tab disabled={state.response.screenFour}>Tickers & City Pairs</Tab>
                            </TabList>
                            <TabPanel><ProfileDetails></ProfileDetails></TabPanel>
                            <TabPanel><MCNumberAndALEI></MCNumberAndALEI></TabPanel>
                            <TabPanel><Subscriptions></Subscriptions></TabPanel>
                            <TabPanel><TickersAndPairs></TickersAndPairs></TabPanel>
                        </Tabs>
                    </div>
                }
            </PageLayout>
        </>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <PageLoader/>,
});
